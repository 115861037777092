import Badge, { BadgeColor } from '@components/v2/Badge/Badge';
import { ELeaveStatus, ILeaveHRVM, IUserLeaveVM } from '@types';
import { parseISO } from 'date-fns';
import React from 'react';

interface IProps {
  leave: ILeaveHRVM | IUserLeaveVM;
}

export const DRAFT_PLAN_STATUS_MAP_HR = {
  [ELeaveStatus.DRAFT]: { label: 'Draft', color: BadgeColor.CORAL },
  [ELeaveStatus.SENT_TO_EMPLOYEE]: {
    label: 'Waiting on Employee',
    color: BadgeColor.CORAL,
  },
  [ELeaveStatus.SENT_TO_HR]: { label: 'Waiting on HR', color: BadgeColor.CORAL },
  [ELeaveStatus.APPROVED_EMPLOYEE]: { label: 'Under Review', color: BadgeColor.CORAL },
  // This state will not show up in this table as it's filtered (will be part of active plans table)
  [ELeaveStatus.APPROVED_HR]: {
    label: (start, end) =>
      parseISO(start) < new Date() && parseISO(end) > new Date()
        ? 'On-Leave'
        : parseISO(start) > new Date()
        ? 'Pre-Leave'
        : 'Returned',
    color: BadgeColor.PRIMARY,
  },
  [ELeaveStatus.REJECTED_HR]: { label: 'Rejected', color: BadgeColor.CORAL },
};

const LeaveStatusBadge = ({ leave }: IProps) => {
  return (
    <>
      <Badge color={DRAFT_PLAN_STATUS_MAP_HR[leave.status]?.color || BadgeColor.SOLID_GREY}>
        {leave.status === ELeaveStatus.APPROVED_HR
          ? DRAFT_PLAN_STATUS_MAP_HR[leave.status].label(leave.expectedLeaveDate, leave.expectedReturnDate)
          : DRAFT_PLAN_STATUS_MAP_HR[leave.status]?.label || 'Unknown'}
      </Badge>
    </>
  );
};

export default LeaveStatusBadge;
